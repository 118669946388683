import React, { useContext, useEffect, useState } from "react";
import HeaderLogged from "../components/HeaderLogged";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Skeleton,
} from "@mui/material";
import api from "../services/api";
import { formatUSD } from "../utils/formatUSD";
import { UserContext } from "../context/UserContext";
import BottomMenu from "../components/BottomMenu";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

// Tipos para os dados
interface Trader {
  name: string;
}

interface Transfer {
  id: number;
  amount: number;
  profit: number;
  createdAt: string;
  trader: Trader;
}

interface Affiliate {
  username: string;
}

interface User {
  username: string;
}

interface Commission {
  id: number;
  amount: number;
  level: number;
  createdAt: string;
  affiliate: Affiliate;
  user: User
}

interface Order {
  id: number;
  amount: number;
  profit: number;
  profitRate: number;
  createdAt: string;
  trader: Trader;
}

interface ApiResponse {
  transfers: Transfer[];
  commissions: Commission[];
  orders: Order[];
}

export default function Statements() {
  const [transfers, setTransfers] = useState<Transfer[]>([]);
  const [commissions, setCommissions] = useState<Commission[]>([]);
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const { decodedToken } = useContext(UserContext)

  useEffect(() => {
    api
      .post<ApiResponse>(`/traders/${decodedToken.id}/statement`)
      .then((response) => {
        const { transfers, commissions, orders } = response.data;
        setTransfers(transfers);
        setCommissions(commissions);
        setOrders(orders);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const formatCellValue = <T extends Record<string, any>>(field: keyof T, row: T): React.ReactNode => {
    const value = row[field];

    if (typeof value === 'number') {
      if (field === 'profitRate') {
        return `${(value * 100).toFixed(2)}%`; // Formatar como porcentagem
      } else if (field === 'amount' || field === "profit") {
        return formatUSD(value); // Formatar como dólar
      } else {
        return value; // Mostrar o número como está
      }
    }

    if (typeof value === 'string' && !isNaN(Date.parse(value))) {
      return new Date(value).toLocaleString(); // Formatar datas
    }

    return String(value ?? ''); // Fallback para string ou vazio
  };

  const renderTable = <T extends { id: number }>(
    title: string,
    data: T[],
    columns: {
      field: keyof T | string;
      headerName: string;
      render?: (value: any, row: T) => React.ReactNode;
    }[]
  ) => (
    <>
      <div className="col-md-12">
        <FaArrowLeft onClick={() => navigate(-1)} color="#fff" /> Back
      </div>
      <Typography variant="h6" style={{ marginTop: "20px" }}>
        {title}
      </Typography>
      <TableContainer component={Paper} style={{ marginBottom: "20px" }}>
        <Table
          sx={{
            background:
              "linear-gradient(258.96deg,rgba(171, 43, 254, 0.49) 0%,rgba(128, 11, 203, 0.22) 31.11%,rgba(128, 11, 203, 0.2) 74.42%)",
            backgroundColor: "#000",
            "th, td": { color: "#fff " },
          }}
        >
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell key={String(col.field)}>{col.headerName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? Array.from({ length: 5 }).map((_, index) => (
                <TableRow key={index}>
                  {columns.map((col, colIndex) => (
                    <TableCell key={colIndex}>
                      <Skeleton variant="text" sx={{ backgroundColor: "#ffffff8d" }} />
                    </TableCell>
                  ))}
                </TableRow>
              ))
              : data.map((row) => (
                <TableRow key={row.id}>
                  {columns.map((col) => (
                    <TableCell key={String(col.field)}>
                      {col.render
                        ? col.render(row[col.field as keyof T], row)
                        : formatCellValue(col.field as keyof T, row)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );

  return (
    <>
      <HeaderLogged />
      <div style={{ padding: "20px" }}>
        {renderTable<Transfer>("Transfers", transfers, [
          { field: "amount", headerName: "Amount" },
          { field: "profit", headerName: "Profit" },
          {
            field: "createdAt",
            headerName: "Date",
            render: (value) => new Date(value).toLocaleString(),
          },
          {
            field: "trader",
            headerName: "Trader",
            render: (_, row) => row.trader.name,
          },
        ])}

        {renderTable<Commission>("Commissions", commissions, [
          { field: "amount", headerName: "Amount" },
          { field: "level", headerName: "Level" },
          {
            field: "createdAt",
            headerName: "Date",
            render: (value) => new Date(value).toLocaleString(),
          },
          {
            field: "affiliate",
            headerName: "Affiliate",
            render: (_, row) => row.user?.username,
          },
        ])}

        {renderTable<Order>("Orders", orders, [
          { field: "amount", headerName: "Amount" },
          { field: "profit", headerName: "Profit" },
          { field: "profitRate", headerName: "Profit Rate" },
          {
            field: "createdAt",
            headerName: "Date",
            render: (value) => new Date(value).toLocaleString(),
          },
          {
            field: "trader",
            headerName: "Trader",
            render: (_, row) => row.trader.name,
          },
        ])}
      </div>
    </>
  );
}
